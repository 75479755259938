/*global app*/
app.reveal = (function ($) {
  var reveal = {
    els: {
      open: 'a[data-open]',
      close: 'a[data-close]',
      modal: '.reveal[data-tour]',
      slider: '#slider-modal',
    },

    init: function () {
      $(document).on('click', reveal.els.open, reveal.els.close, reveal.modalClick);

      $(document).on('open.zf.reveal', reveal.els.modal, function () {
        reveal.tourModal.init($(this), 'open');
      });

      $(document).on('closed.zf.reveal', reveal.els.modal, function () {
        reveal.tourModal.init($(this), 'close');
      });

      $(document).on('closed.zf.reveal', reveal.els.slider, function () {
        reveal.sliderModal.close();
      });
    },

    modalClick: function (e) {
      e.preventDefault();

      reveal.sliderModal.init($(this));
    },

    sliderModal: {
      init: function (el) {
        this.el = el;
        this.modal = $(reveal.els.slider);
        this.id = this.el.data('photos-id');
        this.slider = this.modal.find('.js-slider-modal');
        this.photoHolder = $(`.js-slider-modal-photos[data-photos-id="${this.id}"]`);
        this.photos = this.photoHolder.children();
        this.urls = [];
        this.photos.map(index => {
          const item = $(this.photos[index]);
          this.urls.push({url: item.data('photo'), alt: item.data('alt'), width: item.data('width'), height: item.data('height')});
        });

        this.reset();
        this.open();
      },

      open: function () {
        const modal = this.modal;

        if ((this.urls && this.urls.length) && $.fn.slick) {
          modal.addClass('is-loading');

          $.each(this.urls, index => {
            this.slider.append(`<div><img src="${this.urls[index].url}" alt="${this.urls[index].alt}" width="${this.urls[index].width}" height="${this.urls[index].height}" /></div>`);
          });

          imagesLoaded(modal.toArray(), '.js-slider-modal img', () => {
            const options = {
              speed: 500,
              autoplay: true,
              autoplaySpeed: 4000,
              infinite: true,
              slidesToShow: 1,
              adaptiveHeight: 1,
              slidesToScroll: 1,
              arrows: true,
              prevArrow: '<button type="button" class="slick-prev slick-arrow" role="button"><svg viewBox="0 0 18 18" role="img" aria-label="Previous" focusable="false"><path fill-rule="evenodd" d="M13.703 16.293a1 1 0 1 1-1.415 1.414l-7.995-8a1 1 0 0 1 0-1.414l7.995-8a1 1 0 1 1 1.415 1.414L6.413 9l7.29 7.293z"></path></svg></button>',
              nextArrow: '<button type="button" class="slick-next slick-arrow" role="button"><svg viewBox="0 0 18 18" role="img" aria-label="Next" focusable="false"><path fill-rule="evenodd" d="M4.293 1.707A1 1 0 1 1 5.708.293l7.995 8a1 1 0 0 1 0 1.414l-7.995 8a1 1 0 1 1-1.415-1.414L11.583 9l-7.29-7.293z"></path></svg></button>',
              dots: false,
              fade: true,
            };

            this.slider.slick(options);
            modal.removeClass('is-loading');
          });
        }
      },

      reset: function () {
        this.slider.removeClass('slick-vertical slick-initialized slick-slider').empty();
      },

      close: function () {
        this.reset();
      },
    },

    tourModal: {
      init: function (modal, status) {
        this.modal = modal;
        this.iframe = this.modal.find('iframe');

        status === 'open' ? this.open() : this.close();
      },

      open: function () {
        var modal = this.modal,
            src   = modal.data('tour');

        modal.addClass('is-loading');
        this.iframe.attr('src', src);

        this.iframe.load(function () {
          modal.removeClass('is-loading');
        });
      },

      close: function () {
        this.iframe.attr('src', '');
      },
    },
  };

  $(document).on('ready', reveal.init);

  return reveal;

})(jQuery);
