/* global app */
app.utility = (function ($) {
    var utility = {
        init: function () {
            utility.addAppleBodyClass();
        },

        adminbarOffset: function () {
            var body = $('body'),
                adminbar = $('#wpadminbar');

            return body.hasClass('admin-bar') && adminbar.length ? adminbar.outerHeight() : '0';
        },

        addAppleBodyClass: function () {
            if (navigator.userAgent.match(/iP(hone|od|ad)/i)) {
                jQuery('body').addClass('browser-ios');
            }
        }
    };

    $(document).on('ready', utility.init);

    return utility;

})(jQuery);