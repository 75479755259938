/*global app*/
app.wpcf7 = (function ($) {
    'use strict';

    var wpcf7 = {
        el: '.wpcf7-submit',
        loader: '<div class="spinner"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div>',

        init: function () {
            $(document).on('click', wpcf7.el, wpcf7.submit);
            wpcf7.text = $(wpcf7.el).html();
        },

        submit: function () {
            var btn = $(this),
                form = $(this).parents('.wpcf7:first');

            btn.html(wpcf7.loader);

            form.on('wpcf7:invalid wpcf7:mailfailed wpcf7:spam', function () {
                btn.html(wpcf7.text);

                $('html, body').stop().animate({
                    scrollTop: $('.wpcf7-not-valid').first().offset().top - 100
                }, 500);
            });

            form.on('wpcf7:mailsent', function () {
                btn.html('Sent. Thanks!');
            });
        },
    };

    $(document).on('ready', wpcf7.init);

    return wpcf7;

})(jQuery);