/*global app*/
app.search = (function ($) {
    var search = {
        open: false,

        els: {
            body: 'body',
            toggle: '.js-search-toggle',
            overlay: '.js-search-takeover',
        },

        init: function () {
            search.els.toggle = $(search.els.toggle);
            search.els.overlay = $(search.els.overlay);
            search.els.form = $(search.els.form);
            search.els.body = $(search.els.body);

            search.els.toggle.on('click', function (e) {
                search.open ? search.closeSearch() : search.openSearch();
                e.preventDefault();
            });

            search.els.overlay.on('click', function (e) {
                !$(e.target).closest('form').length && search.open && search.closeSearch()
            });

            $(document).on('keyup', function (e) {
                27 === e.keyCode && search.open && search.closeSearch();
            });
        },

        openSearch: function () {
            search.els.body.addClass('has-search-takeover');
            search.els.overlay.find('input[type="text"]').focus();
            search.open = true;
        },

        closeSearch: function () {
            search.els.body.removeClass('has-search-takeover');
            search.open = false;
        }
    };

    $(document).on('ready', search.init);

    return search;

})(jQuery);