app.lazyLoad = {
  init() {
    const img = new LazyLoad({
      elements_selector: '.js-img-load',
      threshold: 1,
      class_loaded: 'is-loaded',
    });

    const bg = new LazyLoad({
      elements_selector: '.js-bg-load',
      threshold: 1,
      callback_load(el) {
        const imgArea = el.parentNode;
        const imgSrc = el.getAttribute('data-src');
        imgArea.style.backgroundImage = `url('${imgSrc}')`;
        imgArea.classList.add('is-loaded');

        // Wait for animation to finish
        setTimeout(() => {
          imgArea.classList.add('is-done-animating');
        }, 1000);
      },
    });

    return {
      img,
      bg,
    };
  },
};

app.lazyLoad.init();
