app.forms = (function ($) {
    var forms = {
        els: 'input[type="radio"], input[type="checkbox"]',

        init: function () {
            if(!$('body').hasClass('login')) {
                var $blockLabels = $(forms.els);
                new HNY.SelectionButtons($blockLabels);
            }
        },
    };

    $(document).on('ready', forms.init);

    return forms;

})(jQuery);