/* global app */
app.scrollTarget = (function ($) {
    var scrollTarget = {
        init: function () {
            $.targetLinkOffset({
                offset: app.utility.adminbarOffset(),
                buffer: 60,
                animate: true,
            });
        }
    };

    $(document).on('ready', scrollTarget.init);

    return scrollTarget;

})(jQuery);