/*global nav*/
app.mobileNav = (function($) {
    var mobileNav = {
        els: {

        },

        init: function() {

        }
    };

    $(document).on('ready', mobileNav.init);

    return mobileNav;

})(jQuery);