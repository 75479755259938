app.venobox = (function ($) {
    var venobox = {
        init: function () {
            $('.venobox').venobox();
        }
    };

    $(document).on('ready', venobox.init);

    return venobox;

})(jQuery);