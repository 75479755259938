/*global app*/
app.sliders = (function ($) {
  var sliders = {
    els: {
      hero: {
        el: '.js-hero-slider',
        options: {
          infinite: true,
          speed: 900,
          fade: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6000,
          pauseOnHover: false,
          arrows: false,
          dots: true,
          animate: true,
          cssEase: 'cubic-bezier(0.7, 0, 0.3, 1)',
          rows: 0,
          wait: true,
        },
      },
      specs: {
        el: '.js-specs-slider',
        options: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true,
          fade: true,
        },
      },
      feature: {
        el: '.js-feature-slider',
        options: {
          autoplay: true,
          autoplaySpeed: 6000,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: false,
          fade: true,
        },
      },
    },

    init: function () {
      if ($.fn.slick && $.fn.imagesLoaded) {
        $.each(sliders.els, function (index, slider) {
          if (slider.options.wait) {

            sliders.loadImages(slider);
          } else {
            sliders.activate(slider);
          }
        });
      }
    },

    activate: function (slider) {
      $(slider.el).slick(slider.options);
    },

    loadImages: function (slider) {
      const el = $(slider.el);
      imagesLoaded(el.toArray(), { background: 'figure' }, () => {
        el.addClass('images-loaded');
        sliders.activate(slider);
      });
    },
  };

  $(document).on('ready', sliders.init);

  return sliders;

})(jQuery);
