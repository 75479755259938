/*global app*/
app.gmaps = (function ($) {
  var gmaps = {
    init: function () {
      gmaps.createMap();

      $(window).on('changed.zf.mediaquery', function (event, newSize, oldSize) {
        gmaps.createMap();
      });
    },

    zoomLevel: function () {
      return ('small' === Foundation.MediaQuery.current || 'medium' === Foundation.MediaQuery.current) ? 15 : 16;
    },

    createMap: function () {
      // Basic options for a simple Google Map
      // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
      var mapOptions = {
        scrollwheel: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP
        },

        // How zoomed in you want the map to start at (always required)
        zoom: 13,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(49.1752324, -122.6749085),
        mapTypeId: 'satellite'
      };

      // Get the HTML DOM element that will contain your map
      // We are using a div with id="map" seen below in the <body>
      var mapElement = document.getElementById('map');

      // Create the Google Map using our element and options defined above
      var map = new google.maps.Map(mapElement, mapOptions);

      // Icon
      var icon = {
        path: 'M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z',
        fillColor: '#00AEEF',
        fillOpacity: 0.75,
        anchor: new google.maps.Point(
          384 / 2, // width
          512 // height
        ),
        strokeWeight: 1,
        strokeColor: "#ffffff",
        scale: 0.075,
      };

      // Construct the polygon.

      // Studio A
      var northStudio = new google.maps.Polygon({
        paths: [
          {lat: 49.173563, lng: -122.673724},
          {lat: 49.173572, lng: -122.671896},
          {lat: 49.172788, lng: -122.671904},
          {lat: 49.172796, lng: -122.673724}
        ],
        strokeColor: mfs['studio-a'].color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: mfs['studio-a'].color,
        fillOpacity: 0.35
      });

      // Studio B
      var southStudio = new google.maps.Polygon({
        paths: [
          {lat: 49.172796, lng: -122.673724},
          {lat: 49.172788, lng: -122.671904},
          {lat: 49.171767, lng: -122.671904},
          {lat: 49.171763, lng: -122.673724}
        ],
        strokeColor: mfs['studio-b'].color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: mfs['studio-b'].color,
        fillOpacity: 0.35
      });

      // Studio C
      var westStudio = new google.maps.Polygon({
        paths: [
          {lat: 49.176898, lng: -122.677600},
          {lat: 49.176911, lng: -122.675753},
          {lat: 49.175838, lng: -122.675746},
          {lat: 49.175845, lng: -122.677600}
        ],
        strokeColor: mfs['studio-c'].color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: mfs['studio-c'].color,
        fillOpacity: 0.35
      });


      // Studio (A & B) Parking Lot
      icon.fillColor = mfs['studio-a-b-parking'].color;
      var studioABParkingLot = new google.maps.Marker({
        position: {
          lat: 49.17366935972124,
          lng: -122.6635745198501,
        },
        title: 'Studio (A & B) Parking Lot',
        icon: icon,
      });

      // Studio C Parking Lot
      icon.fillColor = mfs['studio-c-parking'].color;
      var studioCParkingLot = new google.maps.Marker({
        position: {
          lat: 49.17675125490801,
          lng: -122.66650285430545,
        },
        title: 'Studio C Parking Lot',
        icon: icon,
      });

      // MBS Equipment
      var mbs = new google.maps.Polygon({
        paths: [
          {lat: 49.178672, lng: -122.678047},
          {lat: 49.178549, lng: -122.677019},
          {lat: 49.178012, lng: -122.677175},
          {lat: 49.178145, lng: -122.678227}
        ],
        strokeColor: mfs['mbs-equipment'].color,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: mfs['mbs-equipment'].color,
        fillOpacity: 0.35
      });

      // Studio X
      icon.fillColor = mfs['studio-x'].color;
      var studioX = new google.maps.Marker({
        position: {
          lat: 49.17622335734026,
          lng: -122.67474953681624,
        },
        title: 'Studio X',
        icon: icon,
      });

      // AnneX
      icon.fillColor = mfs['annex'].color;
      var anneX = new google.maps.Marker({
        position: {
          lat: 49.17645190861418,
          lng: -122.67988621813913,
        },
        title: 'AnneX',
        icon: icon,
      });

      // Martini Acres A
      icon.fillColor = mfs['martini-acres-a'].color;
      var martiniAcresA = new google.maps.Marker({
        position: {
          lat: 49.18881215999093,
          lng: -122.6339539683505,
        },
        title: 'Martini Acres (A)',
        icon: icon,
      });

      // Martini Acres B
      icon.fillColor = mfs['martini-acres-b'].color;
      var martiniAcresB = new google.maps.Marker({
        position: {
          lat: 49.18914518650559,
          lng: -122.64955267238919
        },
        title: 'Martini Acres (B)',
        icon: icon,
      });

      //  Martini Aviation
      icon.fillColor = mfs['martini-aviation'].color;
      var martiniAviation = new google.maps.Marker({
        position: {
          lat: 49.166718440083656,
          lng: -122.5564116631002,
        },
        title: 'Martini Aviation',
        icon: icon,
      });

      northStudio.setMap(map);
      southStudio.setMap(map);
      westStudio.setMap(map);
      studioABParkingLot.setMap(map);
      studioCParkingLot.setMap(map);
      mbs.setMap(map);
      studioX.setMap(map);
      anneX.setMap(map);
      martiniAcresA.setMap(map);
      martiniAcresB.setMap(map);
      martiniAviation.setMap(map);
    },

  };

  if ($('body').hasClass('has-gmap')) {
    google.maps.event.addDomListener(window, 'load', gmaps.init);
  }

  return gmaps;

})
(jQuery);
