app.gallery = (function ($) {
    var gallery = {
        el: '.js-gallery',

        init: function () {
            gallery.el = $(gallery.el);
            gallery.activate();
        },

        activate: function () {
            if ($.fn.imagesLoaded && $.fn.lazyload) {
                const items = gallery.el.toArray();
                imagesLoaded(items, function () {
                    $('.backlot__photo').addClass('not-loaded');
                    $('.backlot__photo.not-loaded').lazyload({
                        effect: 'fadeIn',
                        load: function () {
                            $(this).removeClass('not-loaded');
                        }
                    });
                    $('.backlot__photo.not-loaded').trigger('scroll');
                });
            }
        }
    };

    $(document).on('ready', gallery.init);

    return gallery;

})(jQuery);
