/*global app*/
app.embeds = (function ($) {
    var embeds = {
        els: 'iframe[src*="vimeo.com"], iframe[src*="youtube.com"], [data-tour] iframe',

        init: function () {
            embeds.activate();
        },

        activate: function () {
            embeds.els = $(embeds.els);

            embeds.els.each(function () {
                var el = $(this);
                el.wrap('<div class="responsive-embed widescreen"/>');
            });
        }
    };

    $(document).on('ready', embeds.init);

    return embeds;

})(jQuery);